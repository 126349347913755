/* src/components/Discord.css */
.discord-section {
  display: flex;
  justify-content: flex-end; /* Aligner le contenu à droite */
  align-items: center; /* Centrer verticalement */
  height: 100vh; /* Prendre toute la hauteur de la fenêtre */
  padding-right: 50px; /* Espacement de 50px à droite */
}

.discord-box {
  background-color: rgba(0, 0, 0, 0.8); /* Fond noir doux avec transparence */
  padding: 40px; /* Augmenter le padding pour agrandir le cadre */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Effet ombré */
  color: #fff; /* Couleur du texte */
  max-width: 600px; /* Largeur maximale du cadre */
  text-align: center; /* Centrer le texte */
  font-size: 1.2em; /* Augmenter la taille des caractères */
}

.discord-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #7289da; /* Couleur de fond du bouton Discord */
  color: #fff; /* Couleur du texte du bouton */
  text-decoration: none; /* Supprimer la décoration de texte */
  border-radius: 5px; /* Coins arrondis */
  transition: background-color 0.3s; /* Transition douce pour le changement de couleur */
}

.discord-link:hover {
  background-color: #5b6eae; /* Couleur de fond du bouton au survol */
}