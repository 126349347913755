/* src/components/Signup.css */
.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.signup-box {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.signup-form input {
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1em;
}

.signup-form button {
  padding: 10px 20px;
  background-color: #7289da;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-form button:hover {
  background-color: #5b6eae;
}