/* src/components/Navbar.css */
.navbar {
  background-color: rgba(0, 0, 0, 0.8); /* Fond noir doux avec transparence */
  padding: 10px 20px;
  height: 60px; /* Hauteur de la barre de navigation */
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre pour donner de la profondeur */
  font-family: 'Arial', sans-serif; /* Police plus élégante */
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end; /* Aligner les éléments à droite */
  width: 100%;
}

.navbar-item {
  margin: 0 10px; /* Espacement normal entre les éléments */
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition pour les effets de survol */
}

.navbar-link:hover {
  background-color: #555;
  border-radius: 5px;
  color: #ddd; /* Changer la couleur du texte au survol */
}

.navbar-link:active {
  background-color: #777; /* Couleur de fond lors du clic */
  color: #ccc; /* Couleur du texte lors du clic */
  transform: scale(0.95); /* Légère réduction de la taille pour l'effet de clic */
  transition: transform 0.1s ease; /* Transition pour l'effet de clic */
}