/* src/index.css ou un autre fichier CSS global */

/* Palette de couleurs */
:root {
  --primary-color: #0033CC; /* Bleu Foncé */
  --secondary-color: #FFD700; /* Or */
  --accent-color: #CC3333; /* Rouge */
  --background-color: #333333; /* Gris Foncé */
  --text-color: #C0C0C0; /* Argent */
}

/* Style général du site */
body {
  font-family: 'Garamond', serif; /* Utilise une police de type médiéval */
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  background-image: url('../assets/thumb-1920-417361.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}



/* Boutons */
button {
  background-color: var(--secondary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

/* En-tête */
.header {
  background-image: url('../assets/thumb-1920-417361.jpg'); /* Image de fond inspirée de WoW */
  background-size: cover;
  color: var(--text-color);
  padding: 20px;
  text-align: center;
}
