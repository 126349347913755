/* src/components/HomePage.css */
.home-page {
  display: flex;
  justify-content: flex-end; /* Aligner le contenu à droite */
  align-items: center; /* Centrer verticalement */
  height: 100vh; /* Prendre toute la hauteur de la fenêtre */
  padding-right: 50px; /* Espacement de 50px à droite */
}

.presentation-box {
  background-color: rgba(0, 0, 0, 0.8); /* Fond noir doux avec transparence */
  padding: 40px; /* Augmenter le padding pour agrandir le cadre */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Effet ombré */
  color: #fff; /* Couleur du texte */
  max-width: 600px; /* Augmenter la largeur maximale du cadre */
  text-align: center; /* Centrer le texte */
  font-size: 1.2em; /* Augmenter la taille des caractères */
}